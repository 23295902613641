@value main-font, main-dark, secondary-font from "@/styles/variables.module.css";

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  background: #fff;
  padding: 59px 16px 0 16px;
  width: 100%;
  height: 100%;
}

.xButton {
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.title {
  color: main-dark;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  font-family: main-font;
  text-align: center;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 57px;
  color: main-dark;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: secondary-font;
}
